import React from 'react';
import Swal from 'sweetalert2';
import { Toys } from '@styled-icons/material';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import * as S from './styles';
import AuthService from '~/services/api/AuthService';
import { login, isAuthenticated, isAdmin } from '~/services/auth';

import Button from '~/shared/components/Button';

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Informe o login'),
  password: Yup.string().required('Informe a senha'),
});

export default function Login() {
  const [sending, setSending] = React.useState(false);
  const { register, handleSubmit, errors } = useForm({ validationSchema });

  const history = useHistory();

  React.useEffect(() => {
    if (isAuthenticated()) {
      if (isAdmin()) {
        history.push('/admin');
      } else {
        history.push('/');
      }
    }
  }, []);

  async function onSubmit(data) {
    try {
      setSending(true);
      const response = await AuthService.login(data.login, data.password);
      login(response.data.access_token, response.data.user);
      setSending(false);
      if (isAdmin()) {
        history.push('/admin');
      } else {
        history.push('/');
      }
    } catch (e) {
      setSending(false);
      if (e.response.status === 401) {
        Swal.fire({
          title: 'Credenciais inválidas',
          text: 'Verifique as informações e tente novamente',
          icon: 'error',
        });
      } else {
        Swal.fire({ text: 'Houve uma falha ao efetuar login', icon: 'error' });
      }
    }
  }

  return (
    <S.Container>
      <S.ContentWrapper>
        <S.Project>
          <S.ProjectTitle>
            <Toys />
            {process.env.REACT_APP_PROJECT_FULL_NAME.toUpperCase()}
          </S.ProjectTitle>
        </S.Project>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Title>Acessar Sistema</S.Title>
          <S.Input name="login" placeholder="Login" ref={register} />
          {errors.login && <S.Error>{errors.login.message}</S.Error>}
          <S.Input
            type="password"
            name="password"
            placeholder="Senha"
            ref={register}
          />
          {errors.password && <S.Error>{errors.password.message}</S.Error>}

          <Button
            type="submit"
            margin="15px 0"
            height={50}
            block
            disabled={sending}
            loading={sending}
          >
            ENTRAR
          </Button>
          <Link to="/alterar-senha">Alterar senha</Link>
        </S.Form>
      </S.ContentWrapper>
    </S.Container>
  );
}
