import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 400;
  color: #777;
  padding-bottom: 10px;
  border-bottom: 1px solid #cecece;
  margin-bottom: 15px;
`;
