import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

import { isAuthenticated } from '~/services/auth';

import AppLayout from '~/painel/Layout';

const AppRoute = ({ component: Component, auth, login, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isAuthenticated()) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }

      return (
        <AppLayout>
          <Component {...props} />
        </AppLayout>
      );
    }}
  />
);

AppRoute.defaultProps = {
  auth: false,
  login: false,
  location: {},
};

AppRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.string, propTypes.func]).isRequired,
  auth: propTypes.bool,
  login: propTypes.bool,
  location: propTypes.object,
};

export default AppRoute;
