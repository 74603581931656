import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Close } from '@styled-icons/material';

export const TipoDoc = styled.h1`
  font-size: 18px;
  font-weight: 400;
  color: #777;
  margin-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #777;
`;

export const Box = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 15px;
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  background: #ececec;
  padding: 0 15px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const BoxTitle = styled.h3`
  font-size: 14px;
  font-weight: 300;
`;

export const BoxBody = styled.div`
  padding: 30px 15px;
`;

export const DropZone = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  color: ${({ isDragAccept }) => (isDragAccept ? '#00B767' : '#707070')};
  border: 1px dashed
    ${({ isDragAccept }) => (isDragAccept ? '#00B767' : '#bdbdbd')};
  font-size: 14px;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const SelectedFileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const SelectedFile = styled.span`
  font-size: 1rem;
`;

export const ClearFile = styled(Close)`
  border: 0;
  background: transparent;
  margin-right: 10px;
  color: red;
  width: 1.5rem;
  cursor: pointer;
`;

export const ActionButton = styled(Link)`
  color: inherit;
  padding: 5px;
  border: 0;
  background: transparent;

  svg {
    width: 18px;
  }
`;

export const Form = styled.form``;

export const RadioGroup = styled.div`
  margin: 10px 0;
  padding: 15px 0;
`;

export const RadioLabel = styled.label`
  color: rgba(0, 0, 0, 0.5);
  padding-right: 15px;
`;

export const Error = styled.span`
  display: block;
  color: red;
`;
