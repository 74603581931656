import React from 'react';
import propTypes from 'prop-types';
import { Digital } from 'react-activity';

import * as S from './styles';

const Loading = ({ text, color, size }) => {
  return (
    <S.Container>
      <Digital color={color} size={size} animating />
      <S.Text color={color}>{text}</S.Text>
    </S.Container>
  );
};

Loading.propTypes = {
  text: propTypes.node,
  color: propTypes.string,
  size: propTypes.oneOfType([propTypes.number, propTypes.string]),
};

Loading.defaultProps = {
  text: 'Carregando dados',
  color: '#777',
  size: 48,
};

export default Loading;
