import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import AppRoute from './AppRoute';

import AdminLayout from '~/admin/Layout';
import AdminDashboard from '~/admin/pages/Dashboard';
import Usuarios from '~/admin/pages/Usuarios';
import AddUsuario from '~/admin/pages/AddUsuario';
import UpdateUsuario from '~/admin/pages/UpdateUsuario';
import Documentos from '~/admin/pages/Documentos';
import GerirDocs from '~/admin/pages/GerirDocs';

import Dashboard from '~/painel/pages/Dashboard';
import Arquivos from '~/painel/pages/Arquivos';
import AlterarSenha from '~/painel/pages/AlterarSenha';
import Login from '~/shared/pages/Login';

export default function Routes() {
  return (
    <Switch>
      <AppRoute path="/" exact component={Dashboard} />
      <AppRoute path="/documentos/:category" exact component={Arquivos} />

      <AdminRoute path="/admin" exact component={AdminDashboard} />
      <AdminRoute path="/admin/usuarios" exact component={Usuarios} />
      <AdminRoute
        path="/admin/usuarios/cadastrar"
        exact
        component={AddUsuario}
      />
      <AdminRoute
        path="/admin/usuarios/editar/:id"
        exact
        component={UpdateUsuario}
      />
      <AdminRoute path="/admin/documentos" exact component={Documentos} />
      <AdminRoute
        path="/admin/documentos/gerenciar/:category"
        exact
        component={GerirDocs}
      />

      <Route
        path="/login"
        exact
        component={() => (
          <AdminLayout isLogin>
            <Login />
          </AdminLayout>
        )}
      />

      <Route
        path="/alterar-senha"
        exact
        component={() => (
          <AdminLayout>
            <AlterarSenha />
          </AdminLayout>
        )}
      />
    </Switch>
  );
}
