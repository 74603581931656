import React from 'react';
import propTypes from 'prop-types';
import DataTable from 'react-data-table-component';

import Loading from '~/shared/components/Loading';
import SearchInput from './Search';
// import Pagination from './Pagination';
// import { getPage } from '~/helpers/url';
// import * as S from './styles';

const MyDataTable = ({
  title,
  columns,
  data,
  isLoading,
  onChangePage,
  handleSearch,
  ...props
}) => {
  /* const onChangePage = (page, totalRows) => {
    history.push(`${location.pathname}?page=${page}`);
  }; */

  return (
    <DataTable
      title={title}
      columns={columns}
      data={data.data}
      persistTableHead
      subHeader
      subHeaderAlign="right"
      subHeaderComponent={<SearchInput onChange={handleSearch} />}
      progressPending={isLoading}
      progressComponent={<Loading />}
      pagination
      paginationServer
      // paginationDefaultPage={getPage()}
      // paginationComponent={Pagination}
      paginationTotalRows={data.total}
      paginationPerPage={data.per_page || 15}
      onChangePage={onChangePage}
      paginationComponentOptions={{
        rangeSeparatorText: 'de',
        noRowsPerPage: true,
      }}
      {...props}
      noDataComponent={
        <div style={{ padding: '24px' }}>Nenhum registro encontrado</div>
      }
    />
  );
};

MyDataTable.propTypes = {
  title: propTypes.string,
  columns: propTypes.array.isRequired,
  data: propTypes.oneOfType([propTypes.array, propTypes.object]).isRequired,
  isLoading: propTypes.bool,
  onChangePage: propTypes.func,
  handleSearch: propTypes.func,
};

MyDataTable.defaultProps = {
  title: '',
  isLoading: false,
  onChangePage: null,
  handleSearch: null,
};

export default React.memo(MyDataTable);
