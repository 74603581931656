export function userLogin({ id, name, login, cpf, type }) {
  return {
    type: '@USER/LOGIN',
    payload: {
      id,
      name,
      login,
      cpf,
      type,
    },
  };
}
