import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  padding: 0 64px;
  top: 0;
  width: 100%;
  height: 80px;
  background: #231f20;
  color: #fff;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80%;
  padding-right: 4px;
  border-right: 2px solid rgba(255, 255, 255, 0.3);
  margin-right: 36px;

  a {
    height: 100%;
  }
`;

export const Logo = styled.img`
  max-height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50%;
  padding-right: 64px;
  /* border-right: 2px solid rgba(255, 255, 255, 0.4); */
  margin-right: 36px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 400;
`;

export const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
`;

export const ProjectLabel = styled.h3`
  font-size: 14px;
  font-weight: 300;
  color: #bdbdbd;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const UserLabel = styled.h3`
  font-size: 14px;
  font-weight: 300;
  color: #bdbdbd;
`;

export const UserName = styled.h1`
  font-size: 14px;
  font-weight: 400;
`;

export const LogoutButton = styled.button`
  border: 0;
  background: transparent;
  color: #fff;
  padding: 0;
`;
