import React from 'react';
import propTypes from 'prop-types';

import * as S from './styles';

const SearchInput = ({ onChange, ...props }) => {
  const [searching, setSearching] = React.useState(false);

  const handleChange = e => {
    onChange(e);
    setSearching(e.target.value.length >= 1);
  };

  const clear = () => {
    const element = document.getElementById('inputSearch');
    /**
     * Força o evento CHANGE após alterar o valor
     * https://stackoverflow.com/a/46012210
     */
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(element, '');
    const event = new Event('input', { bubbles: true });
    event.value = '';
    element.dispatchEvent(event);
    setSearching(false);
  };

  return (
    <S.Container>
      <S.SearchIcon />
      <S.SearchInput
        id="inputSearch"
        onChange={e => handleChange(e)}
        {...props}
      />
      {searching && <S.CancelIcon onClick={clear} />}
    </S.Container>
  );
};

SearchInput.propTypes = {
  onChange: propTypes.func.isRequired,
};

export default SearchInput;
