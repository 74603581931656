import React from 'react';
import { Link } from 'react-router-dom';
import { NoteAdd } from '@styled-icons/material';
import { Grid, Row, Col } from 'react-flexbox-grid';

import PageTitle from '~/admin/components/PageTitle';
import Button from '~/shared/components/Button';

import * as S from './styles';

import docs from '~/shared/data/file-categories';

export default function Documentos() {
  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <PageTitle>
            <NoteAdd />
            Gestão de Documentos
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <S.Text>Selecione o tipo de documento</S.Text>
        </Col>
      </Row>
      <Row>
        {docs.map(doc => (
          <Col lg={2} md={3} sm={12} key={doc.id}>
            <Link to={`/admin/documentos/gerenciar/${doc.category}`}>
              <S.TipoContainer>
                <S.TipoIndex>{doc.id}</S.TipoIndex>
                <S.Tipo>{doc.name.toUpperCase()}</S.Tipo>
              </S.TipoContainer>
            </Link>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={12}>
          <Link to="/admin">
            <Button color="default">Retornar</Button>
          </Link>
        </Col>
      </Row>
    </Grid>
  );
}
