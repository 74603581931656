import React from 'react';
import propTypes from 'prop-types';
import { Digital } from 'react-activity';

import * as S from './styles';

const Button = ({
  children,
  loading,
  margin,
  height,
  color,
  block,
  ...props
}) => {
  return (
    <S.Container
      margin={margin}
      height={height}
      color={color}
      block={block}
      {...props}
    >
      {loading ? <Digital color="#fff" size={18} /> : children}
    </S.Container>
  );
};

Button.propTypes = {
  children: propTypes.node,
  loading: propTypes.bool,
  margin: propTypes.string,
  color: propTypes.string,
  height: propTypes.number,
  block: propTypes.bool,
};

Button.defaultProps = {
  children: null,
  loading: false,
  margin: null,
  height: null,
  color: 'primary',
  block: false,
};

export default Button;
