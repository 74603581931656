import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { Controller } from 'react-hook-form';

import Input from '~/shared/components/Forms/Input';
import InputMask from '~/shared/components/Forms/InputMask';
import Radio from '~/shared/components/Forms/Radio';
import { Select, Option } from '~/shared/components/Forms/Select';
import Button from '~/shared/components/Button';

import * as S from './styles';

const FormUsuario = ({
  handleSubmit,
  registerInput,
  errors,
  control,
  sending,
}) => {
  return (
    <S.Form onSubmit={handleSubmit}>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="login">* Informe o login do usuário</S.Label>
          <Input
            type="text"
            name="login"
            placeholder="Login"
            ref={registerInput}
          />
          {errors.login && <S.Error>{errors.login.message}</S.Error>}
        </Col>
        <Col md={2}>
          <S.Label htmlFor="status">Status do usuário</S.Label>
          <Select id="status" name="status" ref={registerInput}>
            <Option value={true}>Ativo</Option> {/* eslint-disable-line */}
            <Option value={false}>Inativo</Option>
          </Select>
          {errors.status && <S.Error>{errors.status.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="cpf">* Informe o CPF do usuário</S.Label>
          <Controller
            as={InputMask}
            mask="999.999.999-99"
            name="cpf"
            control={control}
          />
          {errors.cpf && <S.Error>{errors.cpf.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <S.Label htmlFor="name">* Informe o nome do usuário</S.Label>
          <Input
            type="text"
            name="name"
            placeholder="Nome"
            ref={registerInput}
          />
          {errors.name && <S.Error>{errors.name.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="birthday_date">
            * Informe a data de nascimento do usuário
          </S.Label>
          <Controller
            as={InputMask}
            mask="99/99/9999"
            name="birthday_date"
            control={control}
          />
          {errors.birthday_date && (
            <S.Error>{errors.birthday_date.message}</S.Error>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="password">* Informe a senha</S.Label>
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            ref={registerInput}
          />
          {errors.password && <S.Error>{errors.password.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="password_repeat">* Repita a senha</S.Label>
          <Input
            name="password_confirmation"
            type="password"
            placeholder="Repita a senha"
            ref={registerInput}
          />
          {errors.password_confirmation && (
            <S.Error>{errors.password_confirmation.message}</S.Error>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="type">* Tipo de usuário</S.Label>
          <S.RadioGroup>
            <S.RadioLabel>
              <Radio name="type" value="externo" ref={registerInput} />
              Externo
            </S.RadioLabel>
            <S.RadioLabel>
              <Radio name="type" value="administrador" ref={registerInput} />
              Administrador
            </S.RadioLabel>
          </S.RadioGroup>

          {errors.type && <S.Error>{errors.type.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={2} sm={12}>
          <Link to="/admin/usuarios">
            <Button color="default">Cancelar</Button>
          </Link>
        </Col>
        <Col md={2} mdOffset={2} sm={12} className="d-flex flex-end">
          <Button type="submit" loading={sending} disabled={sending}>
            Cadastrar usuário
          </Button>
        </Col>
      </Row>
    </S.Form>
  );
};

FormUsuario.propTypes = {
  handleSubmit: propTypes.func,
  registerInput: propTypes.func,
  errors: propTypes.object, // eslint-disable-line
  control: propTypes.object, // eslint-disable-line
  sending: propTypes.bool,
};

FormUsuario.defaultProps = {
  handleSubmit: null,
  registerInput: null,
  errors: {},
  control: {},
  sending: false,
};

export default FormUsuario;
