import React from 'react';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { useForm, Controller } from 'react-hook-form';
import { parse, format } from 'date-fns';
import * as Yup from 'yup';

import Input from '~/shared/components/Forms/Input';
import InputMask from '~/shared/components/Forms/InputMask';
import Button from '~/shared/components/Button';
import PageTitle from '~/admin/components/PageTitle';

import * as S from './styles';

import AuthService from '~/services/api/AuthService';

const AlterarSenha = () => {
  const [sending, setSending] = React.useState(false);
  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: Yup.object().shape({
      login: Yup.string().required('Informe o login'),
      cpf: Yup.string().required('Informe o CPF'),
      birthday_date: Yup.string().required('Informe a data de nascimento'),
      password: Yup.string()
        .min(6, 'A senha deve ter pelo menos 6 caracteres')
        .required('Informe uma senha'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
        .required('Repita a senha'),
    }),
  });
  const history = useHistory();

  const onSubmit = async data => {
    setSending(true);
    const dados = {
      ...data,
      cpf: data.cpf.replace(/[^0-9]+/g, ''),
      birthday_date: format(
        parse(data.birthday_date, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      await AuthService.changePassword(dados);
      setSending(false);
      await Swal.fire({ text: 'Senha alterada com sucesso!', icon: 'success' });
      history.push('/login');
    } catch (e) {
      if (e.response.status === 403) {
        Swal.fire({
          text: 'Os dados do usuário são inválidos!',
          icon: 'error',
        });
      } else {
        Swal.fire({
          text: 'Houve uma falha ao alterar a senha.',
          icon: 'error',
        });
      }
      setSending(false);
    }
  };

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12} style={{ marginBottom: '20px' }}>
          <PageTitle>Alterar senha</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="login">* Informe o login do usuário</S.Label>
          <Input type="text" name="login" placeholder="Login" ref={register} />
          {errors.login && <S.Error>{errors.login.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="cpf">* Informe o CPF do usuário</S.Label>
          <Controller
            as={InputMask}
            mask="999.999.999-99"
            name="cpf"
            control={control}
          />
          {errors.cpf && <S.Error>{errors.cpf.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="birthday_date">
            * Informe a data de nascimento do usuário
          </S.Label>
          <Controller
            as={InputMask}
            mask="99/99/9999"
            name="birthday_date"
            control={control}
          />
          {errors.birthday_date && (
            <S.Error>{errors.birthday_date.message}</S.Error>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="password">* Informe a nova senha</S.Label>
          <Input
            name="password"
            type="password"
            placeholder="Nova senha"
            ref={register}
          />
          {errors.password && <S.Error>{errors.password.message}</S.Error>}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <S.Label htmlFor="password_repeat">* Repita a senha</S.Label>
          <Input
            name="password_confirmation"
            type="password"
            placeholder="Repita a senha"
            ref={register}
          />
          {errors.password_confirmation && (
            <S.Error>{errors.password_confirmation.message}</S.Error>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={2} sm={12}>
          <Link to="/login">
            <Button color="default">Cancelar</Button>
          </Link>
        </Col>
        <Col md={2} sm={12} className="d-flex flex-end">
          <Button type="submit" loading={sending} disabled={sending}>
            Alterar senha
          </Button>
        </Col>
      </Row>
    </S.Form>
  );
};

export default AlterarSenha;
