import React from 'react';
import { Toys } from '@styled-icons/material';
import { Grid, Row, Col } from 'react-flexbox-grid';

import * as S from './styles';

import logoWhite from '~/assets/img/logo-white.svg';

export default function Dashboard() {
  return (
    <Grid
      fluid
      className="h-100 d-flex flex-column"
      style={{ paddingTop: '10%' }}
    >
      <Row center="md" className="d-flex flex-column">
        <Col md={12}>
          <S.Logo src={logoWhite} alt="Logo" />
        </Col>
      </Row>
      <Row center="md">
        <Col md={12}>
          <S.Separator />
        </Col>
      </Row>
      <Row center="md">
        <Col md={12}>
          <S.ProjectTitle>
            <Toys />
            {process.env.REACT_APP_PROJECT_FULL_NAME.toUpperCase()}
          </S.ProjectTitle>
        </Col>
      </Row>
    </Grid>
  );
}
