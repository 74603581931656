import styled from 'styled-components';

export const Logo = styled.img`
  width: 100%;
  max-height: 150px;
`;

export const Separator = styled.hr`
  display: flex;
  align-self: center;
  border: 1px solid #e6e6e6;
  margin: 35px 20%;
`;

export const ProjectTitle = styled.h1`
  font-size: 2.5rem;
  color: #777;

  svg {
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
  }
`;
