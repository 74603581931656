import React from 'react';
import propTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ExitToApp } from '@styled-icons/material';
import { useSelector, useDispatch } from 'react-redux';

import * as S from './styles';
import AuthService from '~/services/api/AuthService';
import { logout, isAuthenticated, getUserData } from '~/services/auth';
import logo from '~/assets/img/logo.svg';
import { userLogin } from '~/store/actions/user';

const Header = ({ isLogin }) => {
  const user = useSelector(state => state.user);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated()) {
      const userData = getUserData();
      dispatch(userLogin(userData));
    }
  }, []);

  function logoff() {
    AuthService.logout();
    logout();
    history.push('/login');
  }

  return (
    <S.Container>
      <S.LogoContainer>
        <Link to={location.pathname.includes('/admin') ? '/admin' : '/'}>
          <S.Logo src={logo} />
        </Link>
      </S.LogoContainer>

      {isLogin && (
        <S.TitleContainer>
          <S.Title>SISTEMA EOLO MARON AMBIENTAL</S.Title>
        </S.TitleContainer>
      )}

      {!isLogin && (
        <S.ProjectContainer>
          <S.ProjectLabel>PROJETO:</S.ProjectLabel>
          <S.Title>{process.env.REACT_APP_PROJECT_NAME}</S.Title>
        </S.ProjectContainer>
      )}

      {!isLogin && isAuthenticated() && (
        <>
          <S.UserContainer>
            <S.UserLabel>Usuário logado:</S.UserLabel>
            <S.UserName>
              {user.login} ({user.name})
            </S.UserName>
          </S.UserContainer>
          <S.LogoutButton onClick={() => logoff()} title="Sair do sistema">
            <ExitToApp size={32} />
          </S.LogoutButton>
        </>
      )}
    </S.Container>
  );
};

Header.defaultProps = {
  isLogin: false,
};

Header.propTypes = {
  isLogin: propTypes.bool,
};

export default Header;
