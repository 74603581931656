import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { CloudDownload, RemoveRedEye } from '@styled-icons/material';
import { Grid, Row, Col } from 'react-flexbox-grid';
import * as S from './styles';

import Button from '~/shared/components/Button';
import DataTable from '~/shared/components/MyDataTable';
import FileService from '~/services/api/FileService';
import categoryData from '~/shared/data/file-categories';
import { getPage } from '~/helpers/url';

export default function GerirDocs() {
  const [loading, setLoading] = React.useState(true);
  const [files, setFiles] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(getPage());
  const [search, setSearch] = React.useState(null);

  const { category } = useParams();
  const history = useHistory();
  const location = useLocation();
  const currentCategory = categoryData.find(item => item.category === category);

  const columns = [
    {
      name: 'Nome do arquivo',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Arquivo',
      selector: 'filename',
      sortable: true,
    },
    {
      name: 'Data de inserção',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Ações',
      button: true,
      cell: row => (
        <a
          href={row.type ? row.file_url : row.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.type && (
            <Tooltip content="Baixar">
              <CloudDownload
                size={18}
                color="orange"
                style={{ marginRight: '10px' }}
              />
            </Tooltip>
          )}
          {!row.type && (
            <Tooltip content="Visualizar">
              <RemoveRedEye
                size={18}
                color="blue"
                style={{ marginRight: '10px' }}
              />
            </Tooltip>
          )}
        </a>
      ),
    },
  ];

  React.useEffect(() => {
    loadData();
  }, [category, currentPage, search]);

  async function loadData() {
    setLoading(true);
    const response = await FileService.index(category, currentPage, search);
    setFiles(response.data);
    setLoading(false);
  }

  function onChangePage(newPage) {
    setCurrentPage(newPage);
    history.push(`${location.pathname}?page=${newPage}`);
  }

  async function handleSearch(e) {
    setSearch(e.target.value);
  }

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <S.Title>{currentCategory.name.toUpperCase()}</S.Title>
        </Col>
      </Row>

      <Row>
        <Col md={12} style={{ paddingTop: '10px' }}>
          <DataTable
            title="Lista de documentos"
            columns={columns}
            data={files}
            isLoading={loading}
            onChangePage={onChangePage}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Link to="/">
            <Button color="default">Retornar</Button>
          </Link>
        </Col>
      </Row>
    </Grid>
  );
}
