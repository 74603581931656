import React from 'react';
import propTypes from 'prop-types';

import * as S from './styles';

const ActionButton = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

ActionButton.propTypes = {
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.string,
    propTypes.node,
  ]).isRequired,
};

export default ActionButton;
