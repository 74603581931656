import styled from 'styled-components';
import { darken } from 'polished';

export const TipoContainer = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background: #fff;
  border: 0;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 0 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.16);

  &:hover {
    cursor: pointer;
    background: ${darken(0.07, '#fff')};
  }
`;

export const TipoIndex = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 80%;
  font-size: 1.8rem;
  font-weight: bold;
  color: #acacac;
  padding: 0 10px;
  border-right: 1px solid #777;
`;

export const Tipo = styled.h3`
  margin-left: 15px;
  text-align: left;
  font-size: 0.8rem;
  color: #777;
`;

export const Text = styled.p`
  font-size: 14px;
  color: #777;
  margin: 10px 0;
`;
