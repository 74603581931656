import styled from 'styled-components';
import { Search, Close } from '@styled-icons/material';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  height: 35px;
  padding: 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SearchIcon = styled(Search)`
  height: 70%;
  color: rgba(0, 0, 0, 0.3);
  margin-right: 10px;
`;

export const SearchInput = styled.input.attrs({ placeholder: 'Pesquisar' })`
  width: 100%;
  border: 0;

  ${({ value }) =>
    value &&
    value.length >= 1 &&
    `
    ~ svg.cancel-icon {
      display: none;
    }
  `}
`;

export const CancelIcon = styled(Close).attrs({ className: 'cancel-btn' })`
  height: 70%;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
`;
