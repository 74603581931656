import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { Link } from 'react-router-dom';
import {
  PinDrop,
  SupervisedUserCircle,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@styled-icons/material';

import * as S from './styles';
import fileCategories from '~/shared/data/file-categories';

const menu = [
  {
    id: 10,
    name: 'WEBGIS',
    link: '/',
    icon: <PinDrop />,
    size: 'lg',
    color: 'blue',
  },
  {
    id: 20,
    name: 'Stakeholders',
    link: '/',
    icon: <SupervisedUserCircle />,
    size: 'lg',
    color: 'lightgreen',
  },
];

menu.push(...fileCategories);

export default function Sidebar() {
  const [open, setOpen] = React.useState(true);

  return (
    <S.Container isOpen={open}>
      <S.MenuItems>
        <S.Toggle>
          <Tooltip
            content={open ? 'Retrair menu' : 'Expandir menu'}
            direction="right"
            distance={20}
          >
            <S.ToggleButton onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </S.ToggleButton>
          </Tooltip>
        </S.Toggle>
        {menu.map(item => (
          <Link
            to={item.link ? item.link : `/documentos/${item.category}`}
            key={item.id}
          >
            <Tooltip content={item.name} direction="right" distance={20}>
              <S.Item isOpen={open} size={item.size} color={item.color}>
                {item.icon && item.icon}
                {open && <S.ItemLabel>{item.name}</S.ItemLabel>}
              </S.Item>
            </Tooltip>
          </Link>
        ))}
      </S.MenuItems>
    </S.Container>
  );
}
