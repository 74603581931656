import styled, { css } from 'styled-components';
import { darken } from 'polished';

const sizes = {
  lg: '80px',
};

const colors = {
  green: '#4B7348',
  lightgreen: '#68923C',
  blue: '#177E9F',
};

export const Container = styled.aside`
  width: ${({ isOpen }) => (isOpen ? '250px' : '60px')};
  height: auto;
  min-height: 100%;
  background: #231f20;
`;

export const MenuItems = styled.ul`
  list-style: none;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ size }) => (size ? sizes[size] : '50px')};
  padding: 0 15px;
  margin-bottom: 2px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: normal;
  background: ${({ color }) => (color ? colors[color] : colors.green)};
  cursor: pointer;

  svg {
    margin-right: 12px;
    width: 1.6rem;
    height: 1.6rem;
  }

  &:hover {
    background: ${({ color }) =>
      color ? darken(0.1, colors[color]) : darken(0.1, colors.green)};
  }
  /* Se o menu estive fechado */
  ${props =>
    !props.isOpen &&
    css`
      justify-content: center;

      svg {
        margin: 0;
      }
    `}
`;

export const ItemLabel = styled.span``;

export const Toggle = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #fff;
  padding: 0 10px;
`;

export const ToggleButton = styled.button`
  background: transparent;
  color: #fff;
  border: 0;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;
