import styled from 'styled-components';
import { transparentize, darken } from 'polished';

import bg from '~/assets/img/login_bg.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: none;
  position: absolute;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 5%;
`;

export const Project = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 45px;
`;

export const ProjectTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 400;
  color: #fff;

  svg {
    height: 4rem;
    margin-right: 25px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  height: 400px;
  min-width: 300px;
  padding: 35px;
  background: ${transparentize(0.1, '#707070')};

  a {
    color: #fff;
    align-self: flex-end;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
`;

export const Input = styled.input`
  height: 50px;
  padding: 0 10px;
  margin-top: 15px;
  border-radius: 4px;
  border: 0;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.7);
`;

export const Error = styled.span`
  display: flex;
  padding: 5px;
  background: #e57373;
  color: ${darken(0.4, '#e57373')};
`;
