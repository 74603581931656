import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 48px 64px;
  background-color: #f2f2f2;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100vh;
`;
