import styled from 'styled-components';
import InputMask from 'react-input-mask';

export default styled(InputMask)`
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 15px;
  margin: 5px 0;
  font-size: 14px;
  color: #aaa;
`;
