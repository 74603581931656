import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ActionButton = styled(Link)`
  color: inherit;
  padding: 5px;
  border: 0;
  background: transparent;

  svg {
    width: 18px;
  }
`;
