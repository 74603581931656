import React from 'react';
import Swal from 'sweetalert2';
import Tooltip from 'react-tooltip-lite';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Add,
  Close,
  Check,
  Delete,
  CloudDownload,
  NoteAdd,
} from '@styled-icons/material';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useDropzone } from 'react-dropzone';

import PageTitle from '~/admin/components/PageTitle';
import Input from '~/shared/components/Forms/Input';
import Radio from '~/shared/components/Forms/Radio';
import Button from '~/shared/components/Button';
import DataTable from '~/shared/components/MyDataTable';
import FileService from '~/services/api/FileService';
import categoryData from '~/shared/data/file-categories';
import { getPage } from '~/helpers/url';

import * as S from './styles';

export default function GerirDocs() {
  const [tipo, setTipo] = React.useState(1);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const [name, setName] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [sending, setSending] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(getPage());
  const [search, setSearch] = React.useState(null);

  const { category } = useParams();
  const history = useHistory();
  const location = useLocation();
  const currentCategory = categoryData.find(item => item.category === category);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone();

  const columns = [
    {
      name: 'Nome do arquivo',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Arquivo',
      selector: 'filename',
      sortable: true,
    },
    {
      name: 'Data inserção',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: row => (row.status ? 'Ativo' : 'Inativo'),
    },
    {
      name: 'Ações',
      button: true,
      cell: row => (
        <>
          <Tooltip content="Baixar">
            <S.ActionButton
              as="a"
              href={row.type ? row.file_url : row.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CloudDownload />
            </S.ActionButton>
          </Tooltip>

          <Tooltip content="Excluír">
            <S.ActionButton as="button" onClick={() => deleteFile(row.id)}>
              <Delete />
            </S.ActionButton>
          </Tooltip>

          <Tooltip content={row.status ? 'Desativar' : 'Ativar'}>
            <S.ActionButton as="button" onClick={() => changeStatus(row.id)}>
              {row.status ? <Close /> : <Check />}
            </S.ActionButton>
          </Tooltip>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    loadData();
  }, [currentPage, search]);

  React.useEffect(() => {
    setSelectedFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  async function loadData() {
    setLoading(true);
    const response = await FileService.index(
      category,
      currentPage,
      search,
      true
    );
    setFiles(response.data);
    setLoading(false);
  }

  const onChangePage = newPage => {
    setCurrentPage(newPage);
    history.push(`${location.pathname}?page=${newPage}`);
  };

  async function handleSearch(e) {
    setSearch(e.target.value);
  }

  async function onSubmit(event) {
    event.preventDefault();

    if (tipo && !selectedFile) {
      setErrors({ ...errors, file: 'Selecione um arquivo' });
      return;
    }
    if (!tipo && !url) {
      setErrors({ ...errors, url: 'Informe o link do arquivo' });
      return;
    }
    if (!name) {
      setErrors({ ...errors, name: 'Informe o nome do arquivo' });
      return false;
    }

    const formData = new FormData();
    formData.append('type', tipo);
    formData.append('name', name);
    formData.append('category', category);
    if (tipo) {
      formData.append('document', selectedFile);
    } else {
      formData.append('url', url);
    }

    try {
      setSending(true);
      await FileService.store(formData);
      Swal.fire({
        text: 'Arquivo enviado com sucesso',
        icon: 'success',
      });
      reset();
      setSending(false);
      loadData();
    } catch (e) {
      Swal.fire({
        text: 'Houve uma falha ao enviar o arquivo ',
        icon: 'error',
      });
      setSending(false);
    }
  }

  function reset() {
    setSelectedFile(null);
    setName('');
  }

  async function deleteFile(id) {
    try {
      await FileService.destroy(id);
      Swal.fire({ text: 'Arquivo excluído com sucesso', icon: 'success' });
      loadData();
    } catch (e) {
      Swal.fire({
        text: 'Houve uma falha ao excluir o arquivo',
        icon: 'error',
      });
    }
  }

  async function changeStatus(id) {
    try {
      await FileService.update(id);
      Swal.fire({
        text: 'Status do arquivo alterado com sucesso',
        icon: 'success',
      });
      loadData();
    } catch (e) {
      Swal.fire({
        text: 'Houve uma falha ao alterar o status arquivo',
        icon: 'error',
      });
    }
  }

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <PageTitle>
            <NoteAdd />
            Gestão de Documentos
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <S.TipoDoc>{`${
            currentCategory.id
          } - ${currentCategory.name.toUpperCase()}`}</S.TipoDoc>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <S.Form onSubmit={onSubmit}>
            <S.RadioGroup>
              <S.RadioLabel>
                <Radio
                  name="type"
                  value={1}
                  onChange={() => setTipo(1)}
                  checked={tipo === 1}
                />
                Arquivo físico
              </S.RadioLabel>
              <S.RadioLabel>
                <Radio
                  name="type"
                  value={0}
                  onChange={() => setTipo(0)}
                  checked={tipo === 0}
                />
                Arquivo na nuvem
              </S.RadioLabel>
            </S.RadioGroup>
            <S.Box>
              <S.BoxHeader>
                <S.BoxTitle>Novo Arquivo</S.BoxTitle>
              </S.BoxHeader>
              <S.BoxBody>
                <Row style={{ padding: '10px 0' }} middle="md">
                  {tipo === 1 && (
                    <Col md={4}>
                      {selectedFile && (
                        <S.SelectedFileContainer>
                          <S.ClearFile
                            onClick={() => setSelectedFile(null)}
                            title="Remover arquivo"
                          />
                          <S.SelectedFile>{selectedFile.name}</S.SelectedFile>
                        </S.SelectedFileContainer>
                      )}
                      {!selectedFile && (
                        <>
                          <S.DropZone
                            isDragAccept={isDragAccept}
                            {...getRootProps({
                              // https://github.com/react-dropzone/react-dropzone/issues/928#issuecomment-592803534
                              onClick: e => e.preventDefault(),
                            })}
                          >
                            {isDragActive
                              ? 'Solte o arquivo'
                              : 'Selecione o arquivo ou arraste aqui.'}

                            <input
                              type="file"
                              name="file"
                              {...getInputProps()}
                            />
                          </S.DropZone>
                          {!selectedFile && <S.Error>{errors.file}</S.Error>}
                        </>
                      )}
                    </Col>
                  )}

                  {tipo === 0 && (
                    <Col
                      md={4}
                      style={{
                        fontSize: '14px',
                        height: '80px',
                        color: '#777',
                      }}
                    >
                      * Link do arquivo:
                      <Input
                        placeholder="Link do arquivo"
                        name="url"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                      />
                      {!url && <S.Error>{errors.url}</S.Error>}
                    </Col>
                  )}

                  <Col
                    md={4}
                    style={{ fontSize: '14px', height: '80px', color: '#777' }}
                  >
                    * Nome do arquivo:
                    <Input
                      placeholder="Nome do arquivo"
                      name="name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                    {!name && <S.Error>{errors.name}</S.Error>}
                  </Col>
                  <Col md={2}>
                    <Button
                      type="submit"
                      color="success"
                      block
                      height={70}
                      disabled={sending}
                      loading={sending}
                    >
                      <Add />
                      Adicionar arquivo
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      type="reset"
                      color="warning"
                      block
                      height={70}
                      onClick={() => reset()}
                      disabled={sending}
                    >
                      <Close size={32} />
                      Cancelar adição do arquivo
                    </Button>
                  </Col>
                </Row>
              </S.BoxBody>
            </S.Box>
          </S.Form>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ paddingTop: '10px' }}>
          <DataTable
            title="Lista de documentos"
            columns={columns}
            data={files}
            isLoading={loading}
            onChangePage={onChangePage}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Link to="/admin/documentos">
            <Button color="default">Retornar</Button>
          </Link>
        </Col>
      </Row>
    </Grid>
  );
}
