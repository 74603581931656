import React from 'react';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { parse, format } from 'date-fns';
import * as Yup from 'yup';

import PageTitle from '~/admin/components/PageTitle';
import FormUsuario from '~/admin/components/FormUsuario';
import UserService from '~/services/api/UserService';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  login: Yup.string().required('Informe o login'),
  cpf: Yup.string().required('Informe o CPF'),
  type: Yup.string().required('Informe o tipo de usuário'),
  password: Yup.string()
    .min(6, 'A senha deve ter pelo menos 6 caracteres')
    .required('Informe uma senha'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não conferem')
    .required('Repita a senha'),
  status: Yup.boolean().required('Selecione o status'),
  birthday_date: Yup.string().required('Informe a data de nascimento'),
});

export default function AddUsuario() {
  const { register, handleSubmit, errors, control } = useForm({
    validationSchema,
  });
  const history = useHistory();
  const [sending, setSending] = React.useState(false);

  const onSubmit = async data => {
    const dados = {
      ...data,
      cpf: data.cpf.replace(/[^0-9]+/g, ''),
      birthday_date: format(
        parse(data.birthday_date, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      setSending(true);
      await UserService.store(dados);
      setSending(false);
      await Swal.fire({
        text: 'Usuário cadastrado com sucesso',
        icon: 'success',
      });
      history.push('/admin/usuarios');
    } catch (e) {
      setSending(false);
      if (e.response.status === 422) {
        Swal.fire({
          title: 'Erro de validação!',
          html: Object.values(e.response.data.errors).join('<br/>'),
          icon: 'error',
        });
      } else {
        Swal.fire({ text: 'Falha ao cadastrar usuário!', icon: 'error' });
      }
    }
  };

  return (
    <Grid fluid>
      <Row style={{ marginBottom: '20px' }}>
        <Col md={12}>
          <PageTitle>
            <AiOutlineUserAdd />
            CADASTRO DE USUÁRIO
          </PageTitle>
        </Col>
      </Row>

      <FormUsuario
        handleSubmit={handleSubmit(onSubmit)}
        registerInput={register}
        errors={errors}
        control={control}
        sending={sending}
      />
    </Grid>
  );
}
