import styled from 'styled-components';

export const Container = styled.h1`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 5px 10px 0;
  color: #777;

  svg {
    margin-right: 22px;
    width: 38px;
    height: 38px;
  }
`;
