import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  #root {
     /*position: absolute;*/
    overflow: hidden;
  }

  html, body, #root {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    font-family: Roboto, sans-serif;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    font-family: Roboto, sans-serif;
    cursor: pointer;
  }

  .page-content {
    padding-top: 20px;
  }

  /* Flexbox Helpers */
  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-end {
    justify-content: flex-end;
  }

  .flex-center {
    justify-content: center;
  }

  .flex-middle {
    align-items: center;
  }

  /* Outros Helpers */
  .h-100 {
    height: 100%;
  }

  .react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}
`;
