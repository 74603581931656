import styled from 'styled-components';
import { darken } from 'polished';

const bgColor = '#19a0d8';

export const Container = styled.button`
  display: flex;
  align-items: center;
  background: ${bgColor};
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 130px;
  padding: 0 32px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 0;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background: ${darken(0.1, bgColor)};
  }
`;
