import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0;
`;

export const Text = styled.h1`
  font-size: 2rem;
  color: ${props => props.color || '#777'};
`;
