import React from 'react';
import { FileCopy } from '@styled-icons/material';

const icon = <FileCopy />;

export default [
  { id: 1, name: 'EPA', category: 'epa', icon },
  { id: 2, name: 'SGA', category: 'sga', icon },
  { id: 3, name: 'Planejamento', category: 'planejamento', icon },
  { id: 4, name: 'Equipes', category: 'equipes', icon },
  { id: 5, name: 'Licenças', category: 'licencas', icon },
  { id: 6, name: 'PBA', category: 'pba', icon },
  { id: 7, name: 'Compensação', category: 'compensacao', icon },
  { id: 8, name: 'Projetos Sociais', category: 'projetos-sociais', icon },
  {
    id: 9,
    name: 'Ouvidoria e Dados Gerais',
    category: 'ouvidoria-dados-gerais',
    icon,
  },
];
