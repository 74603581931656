import React from 'react';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { parse, format } from 'date-fns';
import * as Yup from 'yup';

import PageTitle from '~/admin/components/PageTitle';
import FormUsuario from '~/admin/components/FormUsuario';
import Loading from '~/shared/components/Loading';
import UserService from '~/services/api/UserService';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  login: Yup.string().required('Informe o login'),
  cpf: Yup.string().required('Informe o CPF'),
  type: Yup.string().required('Informe o tipo de usuário'),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas não conferem'
  ),
  status: Yup.boolean().required('Selecione o status'),
  birthday_date: Yup.string().required('Informe a data de nascimento'),
});

export default function AddUsuario() {
  const [loading, setLoading] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState({});
  const { register, handleSubmit, errors, setValue, control, reset } = useForm({
    validationSchema,
  });
  const { id } = useParams();
  const history = useHistory();

  React.useState(() => {
    (async () => {
      setLoading(true);
      const response = await UserService.view(id);
      setCurrentUser(response.data);
      reset(response.data);
      setLoading(false);
    })();
  }, []);

  const onSubmit = async data => {
    const dados = {
      id,
      ...data,
      cpf: data.cpf.replace(/[^0-9]+/g, ''),
      birthday_date: format(
        parse(data.birthday_date, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd'
      ),
    };

    try {
      setSending(true);
      await UserService.update(dados);
      setSending(false);
      await Swal.fire({
        text: 'Usuário atualizado com sucesso',
        icon: 'success',
      });
      history.push('/admin/usuarios');
    } catch (e) {
      setSending(false);
      Swal.fire({ text: 'Falha ao atualizar o usuário!', icon: 'error' });
    }
  };

  /* function changeCpf(e) {
    setCurrentUser({ ...currentUser, cpf: e.target.value });
    setValue('cpf', e.target.value);
    e.target.value = formatCpf(e.target.value);
  } */

  return (
    <Grid fluid>
      <Row style={{ marginBottom: '20px' }}>
        <Col md={12}>
          <PageTitle>
            <AiOutlineUserAdd />
            EDIÇÃO DE USUÁRIO
          </PageTitle>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <FormUsuario
          handleSubmit={handleSubmit(onSubmit)}
          registerInput={register}
          errors={errors}
          defaults={currentUser}
          setValue={setValue}
          control={control}
          reset={reset}
          sending={sending}
        />
      )}
    </Grid>
  );
}
