import styled from 'styled-components';
import { darken } from 'polished';

const buttonColors = {
  default: '#b1b1b1',
  primary: '#1AA2DB',
  secondary: '#4dbaf9',
  success: '#00B767',
  warning: '#F2CA03',
};

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => (props.block ? '16px' : '14px')};
  font-weight: normal;
  width: ${props => props.block && '100%'};
  height: ${({ height }) => (height ? `${height}px` : '42px')};
  padding: 0 20px;
  margin: ${props => props.margin || '10px 0'};
  background: ${props => buttonColors[props.color] || buttonColors.primary};
  color: #ffffff;
  border: 0px;
  border-radius: 4px;

  &:hover {
    background: ${props =>
      darken(0.08, buttonColors[props.color] || buttonColors.primary)};
  }

  &:disabled {
    background: ${props =>
      darken(0.2, buttonColors[props.color] || buttonColors.primary)};
  }

  svg {
    margin-right: 10px;
    width: ${({ height }) => (height ? `${height / 1.5}px` : '18px')};
    height: ${({ height }) => (height ? `${height / 1.5}px` : '18px')};
  }
`;
