import BaseApi from '~/services/api/BaseApi';
import { buildQuery } from '~/helpers/url';

class UserService extends BaseApi {
  index(category = null, page = 1, search = null, inactive = false) {
    let baseUrl = '/files';
    const query = { page };

    if (category !== null) {
      baseUrl = `${baseUrl}/${category}`;
    }
    if (search) {
      query.search = search;
    }
    if (inactive) {
      query.inactive = true;
    }

    const queryString = buildQuery(query);

    return this.api.get(`${baseUrl}?${queryString}`);
  }

  store(file) {
    return this.api.post('/files', file, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  view(id) {
    return this.api.get(`/files/${id}`);
  }

  update(id) {
    return this.api.put(`/files/${id}`);
  }

  destroy(id) {
    return this.api.delete(`/files/${id}`);
  }
}

export default new UserService();
