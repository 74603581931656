import React from 'react';

import * as S from './styles';

export default function Footer() {
  return (
    <S.Container>
      <S.Title>
        Maron Ambiental - {process.env.REACT_APP_PROJECT_FULL_NAME}
      </S.Title>
      <S.Version>Versão {process.env.REACT_APP_VERSION}</S.Version>
    </S.Container>
  );
}
