import queryString from 'query-string';

export const getPage = () => {
  const { page } = queryString.parse(window.location.search);
  return page || 1;
};

export const parseQuery = () => {
  return queryString.parse(window.location.search);
};

export const buildQuery = params => {
  return queryString.stringify(params);
};
