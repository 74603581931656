import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  background: #c9c9c9;
  padding: 0 64px;
  bottom: 0;
  position: relative;
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 400;
  color: #231f20;
`;

export const Version = styled.span`
  font-size: 0.8rem;
  font-weight: 300;
`;
