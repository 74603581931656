import axios from 'axios';
import Swal from 'sweetalert2';

import { getToken, setToken, logout } from '~/services/auth';

export default class BaseConfig {
  constructor() {
    this.api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    this.api.interceptors.request.use(async config => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line
        config.headers.Accept = `application/json`; // eslint-disable-line
      }
      return config;
    });

    this.api.interceptors.response.use(
      async response => {
        const refreshToken = response.headers.authorization;
        if (refreshToken) {
          setToken(refreshToken.split(' ')[1]); // token sem o Bearer
        }

        return response;
      },
      async error => {
        if (
          error.response.status === 401 &&
          window.location.pathname !== '/login'
        ) {
          logout();
          await Swal.fire({
            title: 'Sessão expirada',
            text: 'Faça login novamente',
            icon: 'error',
          });
          window.location.href = '/login';
        }

        return Promise.reject(error);
      }
    );
  }
}
