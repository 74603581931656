export const TOKEN_KEY = '@riodovento:token';
export const USER_KEY = '@riodovento:user';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const isAdmin = () => getUserData().type === 'administrador';
export const setToken = token => localStorage.setItem(TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const setUserData = user =>
  localStorage.setItem(USER_KEY, JSON.stringify(user));
export const getUserData = () => JSON.parse(localStorage.getItem(USER_KEY));

export const login = (token, user) => {
  setToken(token);
  setUserData(user);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
};
