import BaseApi from './BaseApi';

class AuthService extends BaseApi {
  login(login, password) {
    return this.api.post('/auth/login', { login, password });
  }

  logout() {
    return this.api.post('/auth/logout');
  }

  changePassword({
    login,
    cpf,
    birthday_date,
    password,
    password_confirmation,
  }) {
    return this.api.post('/auth/change-password', {
      login,
      cpf,
      birthday_date,
      password,
      password_confirmation,
    });
  }
}

export default new AuthService();
