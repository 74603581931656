import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

import { isAuthenticated, isAdmin } from '~/services/auth';

import AdminLayout from '~/admin/Layout';

const AdminRoute = ({ component: Component, auth, login, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      // Se for uma rota protegida e o usuário não estiver logado
      if (!isAuthenticated() || !isAdmin()) {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }

      if (!isAdmin()) {
        return (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }

      // Se não precisar do layout completo
      // EX: Login, Registro
      /* if (blank) {
        return <Component {...props} />;
      } */

      return (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      );
    }}
  />
);

AdminRoute.defaultProps = {
  auth: false,
  login: false,
  location: undefined,
};

AdminRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.string, propTypes.func]).isRequired,
  auth: propTypes.bool,
  login: propTypes.bool,
  location: propTypes.oneOfType([propTypes.string, propTypes.object]),
};

export default AdminRoute;
