import styled from 'styled-components';

export const Form = styled.form``;

export const Label = styled.label`
  display: block;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.5);
`;
export const RadioGroup = styled.div`
  margin: 10px 0;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const RadioLabel = styled.label`
  color: rgba(0, 0, 0, 0.5);
  padding-right: 15px;
`;

export const Error = styled.span`
  display: block;
  color: red;
`;
