import React from 'react';
// import ReactTooltip from 'react-tooltip';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'react-activity/dist/react-activity.css';

import Routes from '~/routes/index';
import GlobalStyle from '~/styles/global';
import store from '~/store';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <GlobalStyle />
        {/* <ReactTooltip place="right" effect="solid" /> */}
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
