import BaseApi from '~/services/api/BaseApi';
import { buildQuery } from '~/helpers/url';

class UserService extends BaseApi {
  index(page = 1, search = null) {
    return search
      ? this.api.get(`/users?search=${search}&page=${page}`)
      : this.api.get(`/users?page=${page}`);
  }

  store(user) {
    return this.api.post('/users', user);
  }

  view(id) {
    return this.api.get(`/users/${id}`);
  }

  update({ id, name, login, cpf, type, status, birthday_date }) {
    return this.api.put(`/users/${id}`, {
      name,
      login,
      cpf,
      type,
      status,
      birthday_date,
    });
  }

  destroy(id) {
    return this.api.delete(`/users/${id}`);
  }
}

export default new UserService();
