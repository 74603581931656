import React from 'react';
import propTypes from 'prop-types';

import * as S from './styles';
import Header from '~/shared/components/Header';
import Footer from '~/shared/components/Footer';

const Layout = ({ children, isLogin }) => {
  return (
    <>
      <Header isLogin={isLogin} />
      <S.Container isLogin={isLogin}>{children}</S.Container>
      {!isLogin && <Footer />}
    </>
  );
};

Layout.propTypes = {
  children: propTypes.element.isRequired,
  isLogin: propTypes.bool,
};

Layout.defaultProps = {
  isLogin: false,
};

export default Layout;
