import React from 'react';
import propTypes from 'prop-types';

import * as S from './styles';

const PageTitle = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

PageTitle.propTypes = {
  children: propTypes.node.isRequired,
};

export default PageTitle;
