import React from 'react';
import Swal from 'sweetalert2';
import Tooltip from 'react-tooltip-lite';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { Edit, Close, Check } from '@styled-icons/material';

import PageTitle from '~/admin/components/PageTitle';
import Button from '~/shared/components/Button';
import DataTable from '~/shared/components/MyDataTable';
import * as S from './styles';
import UserService from '~/services/api/UserService';
import { formatCpf } from '~/helpers/string';
import { getPage } from '~/helpers/url';

export default function Usuarios() {
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(getPage());
  const [search, setSearch] = React.useState(null);

  const history = useHistory();
  const location = useLocation();

  const columns = [
    {
      name: 'Login',
      selector: 'login',
      sortable: true,
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'CPF',
      selector: 'cpf',
      sortable: true,
      cell: row => formatCpf(row.cpf),
    },
    {
      name: 'Tipo',
      selector: 'type',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: row => (row.status ? 'Ativo' : 'Inativo'),
      // cell: row => <Skeleton width="100%" />,
    },
    {
      name: 'Ações',
      button: true,
      cell: row => (
        <>
          <Tooltip content="Editar">
            <S.ActionButton to={`/admin/usuarios/editar/${row.id}`}>
              <Edit />
            </S.ActionButton>
          </Tooltip>

          <Tooltip content={row.status ? 'Desativar' : 'Ativar'}>
            <S.ActionButton as="button" onClick={() => changeStatus(row.id)}>
              {row.status ? <Close /> : <Check />}
            </S.ActionButton>
          </Tooltip>
        </>
      ),
    },
  ];

  React.useEffect(() => {
    getUsers();
  }, [currentPage, search]);

  async function getUsers() {
    setLoading(true);
    const response = await UserService.index(currentPage, search);
    setUsers(response.data);
    setLoading(false);
  }

  async function changeStatus(id) {
    try {
      await UserService.destroy(id);
      getUsers();
      Swal.fire({ text: 'Status alterado com sucesso', icon: 'success' });
    } catch (e) {
      Swal.fire({ text: 'Houve uma falha ao alterar o status', icon: 'error' });
    }
  }

  function onChangePage(newPage) {
    setCurrentPage(newPage);
    history.push(`${location.pathname}?page=${newPage}`);
  }

  async function handleSearch(e) {
    setSearch(e.target.value);
  }

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <PageTitle>
            <AiOutlineUserAdd />
            GESTÃO DE USUÁRIOS
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link to="/admin/usuarios/cadastrar">
            <Button>NOVO USUÁRIO</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <DataTable
            title="Lista de usuários"
            columns={columns}
            data={users}
            isLoading={loading}
            onChangePage={onChangePage}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Link to="/admin">
            <Button color="default">Retornar</Button>
          </Link>
        </Col>
      </Row>
    </Grid>
  );
}
