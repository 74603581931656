import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { AiOutlineFileAdd, AiOutlineUserAdd } from 'react-icons/ai';

import ActionButton from '~/admin/components/ActionButton';

export default function Dashboard() {
  return (
    <>
      <Grid fluid>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <Link to="/admin/usuarios">
              <ActionButton>
                <AiOutlineUserAdd size={70} />
                CADASTRO DE USUÁRIOS
              </ActionButton>
            </Link>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <Link to="/admin/documentos">
              <ActionButton>
                <AiOutlineFileAdd size={70} />
                GESTÃO DE DOCUMENTOS
              </ActionButton>
            </Link>
          </Col>
        </Row>
      </Grid>
    </>
  );
}
